import * as React from "react";
import { graphql } from "gatsby";
import {
  Container,
  Box,
  Image,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import SeminarRegistration from "../../components/signUp/SeminarRegistration";
import usePrismicSignUp from "../../hooks/usePrismicSignUp";
const Signup = () => {
  const { language } = useTranslation();
  const signUpData = usePrismicSignUp()
  return (
    <Main>
      <Flex
        bg="#F1F2F4"
        justifyContent="space-between"
        w="full"
        h="27.5rem"
        mb="3.75rem">
        <Seo
          title={signUpData.navText}
          description={signUpData.title}
          link={`https://www.fsm.am/${language}/sign-up`}/>
        <Flex
          direction={{base: 'column', sm: 'row'}}
          flex="1"
          pos="relative">
          <Container
            zIndex="5"
            background="linear-gradient(270deg, rgba(241,242,244,0) 0%, #F1F2F4 50%)"
            position="relative"
            display="flex"
            maxW="7xl"
            alignItems="center"
            height="100%">
            <Box>
              <Box
                top="0"
                left="15px"
                position="absolute">
                <SmallNav>
                  {signUpData.navText}
                </SmallNav>
              </Box>
              <Heading
                as="h1"
                textTransform="capitalize"
                fontSize={{base: '2xl', sm: "28px"}}>
                  {signUpData.title}
              </Heading>
            </Box>
          </Container>
          <Image
            position={{base: 'relative', sm: "absolute"}}
            right="0"
            h="100%"
            zIndex="1"
            objectFit="cover"
            src={signUpData.image}
            w="734px"
            />
        </Flex>
      </Flex>
      <Container
        px="5"
        pb="100px"
        maxW="7xl">
        <SeminarRegistration signUpData={signUpData} />
      </Container>
    </Main>
  );
};

export default Signup;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
