import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicSignUp = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicSignUp {
      allPrismicSignup(filter: {tags: {in: "sign_up"}}) {
        nodes {
          lang
          data {
            title {
              text
            }
            navtext
            cover {
              url
            }
            inputlabel
            inputlabel1
            inputlabel2
            inputlabel3
            inputlabel4
            inputlabel5
            inputlabel6
            inputlabel7
            sendbtn
            description {
              richText
            }
          }
        }
      }
    }
  `);

    return sanitize(response.allPrismicSignup.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        title: response.title.text,
        navText: response.navtext,
        sendBtn: response.sendbtn,
        image: response.cover.url,
        description: response.description.richText,
        inputLabel: response.inputlabel,
        inputLabel1: response.inputlabel1,
        inputLabel2: response.inputlabel2,
        inputLabel3: response.inputlabel3,
        inputLabel4: response.inputlabel4,
        inputLabel5: response.inputlabel5,
        inputLabel6: response.inputlabel6,
        inputLabel7: response.inputlabel7,
    }
}

export default usePrismicSignUp;