import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Stack,
  Flex,
  Button,
  useToast,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InputField } from "../../components/inputfield";
import RichText from "../PrismicRichText";
import http from "../../http";

const SANITIZED_LABELS = {
  companyName: "Company Name",
  fullName: "Full Name",
  email: "Email",
  phoneNumber: "Phone Number",
  organization: "Organization",
  eventName: "Event Name",
  participants: "Participants",
  date: "Date",
};

const SeminarRegistration = ({ signUpData }) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    const data = { FORM: "SIGN UP" };
    Object.entries(values).forEach(
      ([key, value]) => (data[SANITIZED_LABELS[key]] = value)
    );
    setIsLoading(true);
    http
      .post("/send-email", { data })
      .then(() => {
        toast({
          status: "success",
          title: "Նամակը հաջողությամբ ուղարկվել էsuccessfully",
        });
      })
      .catch((e) => {
        toast({
          status: "error",
          title: e?.message || "Network error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Flex
      direction={{
        base: "column",
        sm: "row",
      }}
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Stack
        mb="10"
        spacing="4"
        maxW="29.5rem"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          isInvalid={!!errors.companyName}
          maxW="100%"
          label={"Ուսումնական հաստատության կամ կազմակերպության անվանում"}
          labelProps={{
            fontWeight: "bold",
            fontSize: "sm",
          }}
          {...register("companyName", {
            required: true,
          })}
        />
        <Text fontSize="sm" fontWeight="bold">
          Կոնտակտային տվյալներ
        </Text>
        <InputField
          isInvalid={!!errors.fullName}
          label={"Անուն Ազգանուն"}
          {...register("fullName", {
            required: true,
          })}
        />
        <InputField
          isInvalid={!!errors.email}
          type="email"
          label={"Էլ․ փոստ"}
          {...register("email", {
            required: true,
          })}
        />
        <InputField
          isInvalid={!!errors.phoneNumber}
          type="number"
          label={"Բջջային հեռախոսահամար"}
          {...register("phoneNumber", {
            required: true,
          })}
        />
        <Stack>
          <Text fontSize="sm" fontWeight="bold">
            Միջոցառման անվանում
          </Text>
          <UnorderedList pl="5">
            <ListItem>Սեմինարներ աշակերտների և ուսանողների համար</ListItem>
            <ListItem>Աշակերտների հյուրընկալում Գրասենյակում</ListItem>
            <ListItem>Ֆինանսական ճամբար</ListItem>
            <ListItem>Պրակտիկայի հատուկ ծրագիր</ListItem>
            <ListItem>Հաշտ ճամբար</ListItem>
            <ListItem>Դատախաղ</ListItem>
            <ListItem>
              Զրույց կանանց հետ ֆինանսական ծառայությունների մասին
            </ListItem>
          </UnorderedList>
        </Stack>
        <InputField
          isInvalid={!!errors.participants}
          type="number"
          label={"Միջոցառման մասնակիցների հնարավոր առավելագույն քանակ"}
          {...register("participants", {
            required: true,
          })}
        />
        <InputField
          isInvalid={!!errors.date}
          type="date"
          label={"Միջոցառման կազմակերպման նախընտրելի ամսաթիվ"}
          {...register("date", {
            required: true,
          })}
        />

        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          w="full"
          variant="green"
          type="submit"
        >
          {"Ուղարկել"}
        </Button>
      </Stack>
    </Flex>
  );
};

export default SeminarRegistration;
